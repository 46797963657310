import React, { Component } from 'react';
import logo from '../assets/images/logo.svg';
import { sendRequest } from '../utils/request';

class App extends Component {

    constructor(props){
      super(props);
      this.state = {
        pollDetails: null,
        voteId: null,
        results: null,
        showResults: false,
        calculations: []
      };
    }

    componentDidMount(){
      const pollId = window.default_poll_id || 1;
      const pollDetailsUrl = `http://localhost:8000/api/poll/${pollId}/details`;
      sendRequest({url: pollDetailsUrl})
      .then(response => this.setState({ pollDetails: response }))
      .catch((error) => console.log( error ))
    }

    voteAndGetResults(){
      if(this.state.showResults === true) return;
      const voteId = this.state.voteId;
      if(voteId === null) return;
      const pollVoteUrl = `http://localhost:8000/api/poll/vote_answer/${voteId}`;
      sendRequest({url: pollVoteUrl})
      .then(response => this.calculateResults(response))
      .catch((error) => console.log( error ))
    }

    returnLoadingWheel(){
      return 'Cargando';
    }

    calculateResults(results){
      this.setState({showResults: true})
      let totalVotes = 0;
      const calcs = [];
      results.answers.map(answer =>  totalVotes+= parseInt(answer.votes));
      results.answers.map(answer => {
        calcs[answer.id] = { votes: parseInt(answer.votes), text: answer.text, percentage: Math.round(((answer.votes/totalVotes)*100))}
      });
      this.setState({
        calculations: calcs
      })
    }

    renderResultForOption(answerId){
      if(!this.state.calculations[answerId]) return;
      const result = this.state.calculations[answerId];
      setTimeout(() => {
        let nones = document.querySelectorAll('.displayNone');
        nones.forEach(element => element.classList.remove('displayNone'))
      }, 1500)
      return (
        <div className={`result-option`}>
          <div className={`bar-percentage`} style={{ width: result.percentage +'%'}}>{result.percentage}% ({result.votes})</div>
        </div>
      )
    }

    renderOptions(poll){
      return poll.answers.map(answer => {
        const isSelected = this.state.voteId === answer.id ? 'selected' : '';
       return (
         <React.Fragment key={answer.id}>
            <div onClick={()=> this.state.showResults === false ? this.setState({voteId: answer.id}) : '' } className={`poll-answer ${isSelected}`}>{answer.text}</div>
            {this.renderResultForOption(answer.id)}
          </React.Fragment>
       )
      })
    }

    render() {
      if(this.state.pollDetails === null) return this.returnLoadingWheel();
      const poll = this.state.pollDetails;

        return (
            <div className="App">
              <div className="poll" style={{ background: `linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)),url(${poll.picture})`}}>
                <h4>{poll.title}</h4>
                {this.renderOptions(poll)}
                <div style={{textAlign: 'center'}} onClick={() => this.voteAndGetResults()}><div className="poll-vote">Votar</div></div>
              </div>
          </div>
        );
    }
} 

export default App;
