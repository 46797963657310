import 'whatwg-fetch';

export const sendRequest = (params) => {
    const config = { method: params.method || 'GET',
       // mode: 'cors',
        cache: 'default'
    };
    config.headers = {
        //'Content-Type': 'application/json'
    };

    return fetch( params.url, config )
    .then((response) => response.json())
    .catch((error) => console.log( error ))
}